import React, { useState, useEffect } from 'react';
import scheduleData from './scheduleData.json';

const days = ['Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък'];

function getLectureStatus(index, lecturesForDay, day) {
    const now = new Date();
    const currentDayName = days[now.getDay() - 1]; // Get the name of the current day

    // Only change the color if it's the current day
    if (day !== currentDayName) {
        return 'status-default'; // Use the default status for other days
    }

    const currentTime = now.getTime();

    // Get current lecture start and end time
    const [start, end] = lecturesForDay[index].hour.split('-').map(time => {
        const [hours, minutes] = time.split(':').map(Number);
        return new Date().setHours(hours, minutes, 0, 0);
    });

    if (currentTime > end) {
        return 'status-red'; // The lecture has ended today
    }

    // If the lecture is ongoing, mark as 'status-green'
    if (currentTime >= start && currentTime <= end) {
        return 'status-green';
    }

    // Check if the next lecture should be marked as 'orange'
    if (index > 0) {
        const [prevStart, prevEnd] = lecturesForDay[index - 1].hour.split('-').map(time => {
            const [hours, minutes] = time.split(':').map(Number);
            return new Date().setHours(hours, minutes, 0, 0);
        });

        // Check if this is the first lecture after the ongoing one
        if (currentTime > prevEnd && currentTime < start) {
            return 'status-orange';
        }
    }

    // If the lecture hasn't started yet and is not the next lecture, mark as 'status-default'
    if (currentTime < start) {
        return 'status-default';
    }

    // Default to 'status-default' for all other cases
    return 'status-default';
}


export default function Schedule() {
    const [filteredSchedule, setFilteredSchedule] = useState(scheduleData.lectures);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDay, setSelectedDay] = useState('');

    useEffect(() => {
        const filtered = scheduleData.lectures.filter(lecture =>
            (lecture.lecture.toLowerCase().includes(searchTerm.toLowerCase()) ||
                lecture.lecturer.toLowerCase().includes(searchTerm.toLowerCase()) ||
                lecture.type.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (selectedDay === '' || lecture.day === selectedDay)
        );
        setFilteredSchedule(filtered);
    }, [searchTerm, selectedDay]);

    return (
        <div className="schedule-container">
            <h1>Всички лекции</h1>

            <div className="filters">
                <input
                    type="text"
                    placeholder="Търсете лекции, преподаватели или типове..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <select value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)}>
                    <option value="">Всички дни</option>
                    {days.map(day => (
                        <option key={day} value={day}>{day}</option>
                    ))}
                </select>
            </div>

            {days.filter(day => filteredSchedule.some(lecture => lecture.day === day)).map(day => {
                const lecturesForDay = filteredSchedule.filter(lecture => lecture.day === day);
                let isOrangeAssigned = false; // Track if an "orange" status has been assigned

                return (
                    <div key={day}>
                        <h2>{day}</h2>
                        <hr />
                        <br />
                        <div className="schedule-grid">
                            {lecturesForDay.map((lecture, index) => {
                                // Get the status of the current lecture
                                let lectureStatus = getLectureStatus(index, lecturesForDay, day);

                                // Logic to set the "orange" status for the next lecture
                                if (!isOrangeAssigned && lectureStatus === 'status-default' && index > 0) {
                                    const prevLectureStatus = getLectureStatus(index - 1, lecturesForDay, day);
                                    if (prevLectureStatus === 'status-green') {
                                        lectureStatus = 'status-orange';
                                        isOrangeAssigned = true; // Mark that we've assigned "orange" to the next lecture
                                    }
                                }

                                return (
                                    <div key={index} className={`lecture-card ${lectureStatus}`}>
                                        <h2>{lecture.lecture} <span className="day">{lecture.day}</span></h2>
                                        <div className="lecture-details">
                                            <p><strong>Час:</strong> {lecture.hour}</p>
                                            <p><strong>Тип:</strong> {lecture.type}</p>
                                            <p><strong>Преподавател:</strong> {lecture.lecturer}</p>
                                            <p><strong>Стая:</strong> {lecture.roomNo}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar({ isDarkMode, toggleDarkMode }) {
    return (
        <nav className="navbar">
            <ul className="navbar-links">
                <li><Link to="/">Текуща</Link></li>
                <li><Link to="/all-lectures">Всички</Link></li>
                <li>
                    <button onClick={toggleDarkMode} className="toggle-dark-mode">
                        {isDarkMode ? (
                            <img src="/assets/icons/sun-icon.svg" alt="Икона на слънце" width="24" height="24" />
                        ) : (
                            <img src="/assets/icons/moon-icon.svg" alt="Икона на луна" width="24" height="24" />
                        )}
                    </button>
                </li>
            </ul>
        </nav>
    );
}

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import CurrentLecture from './CurrentLecture';
import Schedule from './Schedule';
import './App.css';

function App() {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        // Initialize dark mode state from localStorage
        return localStorage.getItem('darkMode') === 'true';
    });

    useEffect(() => {
        // Apply dark mode class to body on mount and update
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
        // Save dark mode preference to localStorage
        localStorage.setItem('darkMode', isDarkMode);
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode(prevMode => !prevMode);
    };

    return (
        <Router>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap" rel="stylesheet"/>
            <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
                <Navbar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
                <Routes>
                    <Route path="/" element={<CurrentLecture/>}/>
                    <Route path="/all-lectures" element={<Schedule/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
